import * as React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './features/Home';
import About from './features/About';
import Contact from './features/Contact';
import Services from './features/Services';
import ScrollToTop from './components/ScrollToTop';
import Landing from './features/Admin/Landing';
import Content from './features/Content';



const Router = () => {
    return (
        <BrowserRouter>
        <React.Fragment>
            <ScrollToTop />
            <Switch>
                <Route exact={true} path="/" component={Home}/>
                <Route path="/admin/landing" component={Landing} />
                <Route path="/about" component={About} />
                <Route path="/contact" component={Contact} />
                <Route path="/services" component={Services} />
                {/* Not Found */}
                {/* <Route path="/contact" component={Contact} /> */}
                {/* <Route path="/cottage" component={Airbnb} /> */}
                <Route component={() => <Redirect to="/"/>} />
            </Switch>
            </React.Fragment>
        </BrowserRouter>    
    );
};

export default Router;

