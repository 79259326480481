import * as React from 'react';
import './style.scss';


export default function LogoGrid(){

    
    const images=[
        {id:1,src:{image: require('../../images/logos/live1.png')}, title:'Audi'},
        {id:2,src:{image: require('../../images/logos/live2.png')}, title:'Volvo'},
        {id:3,src:{image: require('../../images/logos/live3.png')}, title:'Volkswagen'},
        {id:4,src:{image: require('../../images/logos/live4.png')}, title:'Vauxhall'},
        {id:5,src:{image: require('../../images/logos/live5.png')}, title:'Toyota'},
        {id:6,src:{image: require('../../images/logos/live6.png')}, title:'Saab'},
        {id:7,src:{image: require('../../images/logos/live7.png')}, title:'Renault'},
        {id:8,src:{image: require('../../images/logos/live8.png')}, title:'Peugeot'},
        {id:9,src:{image: require('../../images/logos/live9.png')}, title:'Nissan'},
        {id:10,src:{image: require('../../images/logos/live10.png')}, title:'Mitsubishi'},
        {id:11,src:{image: require('../../images/logos/live11.png')}, title:'Mercedes-Benz'},
        {id:12,src:{image: require('../../images/logos/live12.png')}, title:'Mazda'},
        {id:13,src:{image: require('../../images/logos/live13.png')}, title:'Citroen'},
        {id:14,src:{image: require('../../images/logos/live14.png')}, title:'Lamborghini'},
        {id:15,src:{image: require('../../images/logos/live15.png')}, title:'Kia'},
        {id:16,src:{image: require('../../images/logos/live16.png')}, title:'Jaguar'},
        {id:17,src:{image: require('../../images/logos/live17.png')}, title:'Hyundai'},
        {id:18,src:{image: require('../../images/logos/live18.png')}, title:'Honda'},
        {id:19,src:{image: require('../../images/logos/live19.png')}, title:'Ford'},
        {id:20,src:{image: require('../../images/logos/live20.png')}, title:'Fiat'},
        {id:21,src:{image: require('../../images/logos/live21.png')}, title:'BMW'},
        {id:22,src:{image: require('../../images/logos/live22.png')}, title:'Alfa Romeo'},
        {id:23,src:{image: require('../../images/logos/live23.png')}, title:'Land Rover'},
        {id:24,src:{image: require('../../images/logos/live24.png')}, title:'Rolls Royce'},
        {id:25,src:{image: require('../../images/logos/live25.png')}, title:'Porsche'},
        {id:26,src:{image: require('../../images/logos/live26.png')}, title:'Suzuki'},

    ];

    const getImages = () =>{
        const imgList: any =[];
        images.forEach((element) =>{
            imgList.push(<img src={element.src.image} alt={element.title} className='px-2 py-2' key={element.id} />)
        })
        return imgList;
    }

    return (
        <div className="container py-4 mb-5 text-center">
            <div className="image-row">
                {getImages()}
            </div>
        </div>
    );

};