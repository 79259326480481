import { FETCH_CONTENT, FETCH_CONTENT_PENDING } from './action';
import { IContent} from '../interfaces';

export interface IContentState {
    loading: boolean,
    data: IContent[],
    error: any
}

const initialState:IContentState = {    
    loading: false,
    data: [{id:"",pageId: "", content:""}],
    error: null
};

export default function contentReducer(state = initialState, action: { type: any; data: IContent[]; }) {
    switch (action.type) {
        case FETCH_CONTENT_PENDING:
            return {...state, loading: true}
        case FETCH_CONTENT:                
            return {
                ...state,
                data: action.data,
                loading: false
            }
        default:
            return state;
    }
}

