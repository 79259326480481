import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import Info from '../../components/Info';
import PageContent from '../../components/PageContent';

export default function About(){

    return(
        <div>
            <MainLayout page="/about">             
            <PageContent pageId="about" />
            </MainLayout>        
        </div>
    )
};