import * as React from 'react';


export default function Footer(){

    return(
        <footer className="py-1">
            <div className="container">
            <p className="m-0 text-center">&copy; Acheson-Crow Ltd 2020 - All Rights Reserved</p>
            </div>        
        </footer>
    );
}