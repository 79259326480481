import * as React from 'react';
import Heading from '../Heading';
import { useDispatch, useSelector } from 'react-redux';
import fetchLanding from '../../Api/index'
import { IIntroState } from '../../store/landing-reducer';
import ReactMarkdown from 'react-markdown';
import LoadingSpinner from '../LoadingSpinner';

interface ILandingState {
  intro: IIntroState
}

export interface ILanding {
  id: string;
  heading: string;
  lead: string;
  content: string
};

export default function Intro() {


  const
    dispatch = useDispatch(),
    selector = useSelector((state: ILandingState) => state);

  React.useEffect(() => {
    dispatch(fetchLanding());
  }, [dispatch]);



  const buildIntro = () => {
    let intro: any = [];

    selector.intro.data?.forEach(d => {
      intro.push(
        <React.Fragment key={d.id}>
          <Heading heading={d.heading} lead={d.lead} />
          <ReactMarkdown source={d.content} />
        </React.Fragment>)
    });
    return intro;
  };

  var output = (<LoadingSpinner/>);

  return (             
      <div className="container">
        {
         selector.intro.loading 
            ? output  
            : buildIntro()
        }
      </div>    
  );

};