
export const FETCH_INTRO_DATA = 'FETCH_INTRO_DATA'; 
export const FETCH_INTRO_PENDING = 'FETCH_INTRO_PENDING';

export const FETCH_CONTENT = 'FETCH_CONTENT'; 
export const FETCH_CONTENT_PENDING = 'FETCH_CONTENT_PENDING';

export const UPDATE_CONTENT = 'UPDATE_CONTENT'; 
export const UPDATE_CONTENT_PENDING = 'UPDATE_CONTENT_PENDING'; 
export const UPDATE_CONTENT_ERROR = 'UPDATE_CONTENT_ERROR';
