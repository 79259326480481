import * as React from 'react';
import './style.scss';


export default function CallOut(){

    return(
        <section className="py-5 bg-full">            
        </section>
    )
};