import * as React from 'react';
import {FETCH_INTRO_DATA, FETCH_INTRO_PENDING} from '../store/action';
import { ILanding } from '../components/Intro';
import axios from 'axios';
import {API_URL} from '../common/'



// export default function fetchLanding() {    

//     return (dispatch: (arg0: { type: string; data: ILanding[]; }) => any) => {         
//         //axios.get('https://localhost:8072/Intro')
//         axios.get(`${API_URL}/Intro`)
//         .then (res =>
//             dispatch({
//                 type: FETCH_INTRO_DATA,
//                 data: res.data as ILanding[]
//             })       
//         )             
    
//     };
// };

export default function fetchLanding() {    

    return (dispatch: (action: any) => void, getState: any) => {         
        //axios.get('https://localhost:8072/Intro')
        dispatch({type: FETCH_INTRO_PENDING});
        axios.get(`${API_URL}/Intro`)
        .then (res =>
            dispatch({
                type: FETCH_INTRO_DATA,
                data: res.data as ILanding[]
            })       
        )             
    
    };
};

