import * as React from 'react';
import MainLayout from '../../components/Layout/Main';
import Info from '../../components/Info';
import PageContent from '../../components/PageContent';

export default function Services(){

    return(
        <div>
            <MainLayout page="/services">             
                <PageContent pageId="services" />
            </MainLayout>        
        </div>
    )
};