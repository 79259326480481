import { FETCH_INTRO_DATA, FETCH_INTRO_PENDING } from './action';
import { ILanding } from '../components/Intro';

export interface IIntroState {
    loading: boolean,
    data: ILanding[],
    error: any
}

const initialState:IIntroState = {
    loading: true,    
    data: [{id:"", heading:"", lead:"", content:""}],
    error: null
};

export default function introReducer(state = initialState, action: { type: any; data: ILanding[]; }) {
    switch (action.type) {
        case FETCH_INTRO_PENDING:
            return { ...state, loading: true}
        case FETCH_INTRO_DATA:                    
            return {
                ...state,
                data: action.data,
                loading: false
            }
        default:
            return state;
    }
}

