import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { IContent } from '../../interfaces';
import { IContentState } from '../../store/content-reducer';
import { useDispatch, useSelector } from 'react-redux';
import { getContentsForPage } from '../../Api/About';
import LoadingSpinner from '../LoadingSpinner';

interface IState {
    content: IContentState
};

interface IProps {
    pageId: string;
}

export default function PageContent(props: IProps) {

    const
        dispatch = useDispatch(),
        selector = useSelector((state: IState) => state);

    React.useEffect(() => {
        dispatch(getContentsForPage(props.pageId));
    }, [dispatch])

    const getContent = () => {
        let content: any = [];
        selector.content.data.forEach(d => {
            content.push(
                <div className="container" key={d.id}>
                    <ReactMarkdown source={d.content} />
                </div>
            )
        });
        return content;
    };

    var output = (<LoadingSpinner/>);
    
    return (
        <React.Fragment>
            {
                selector.content.loading
                ? output
                : getContent()
            }
        </React.Fragment>
    );
};