import {Action, Reducer, Dispatch, combineReducers} from "redux";
import introReducer from './landing-reducer'; 
import contentReducer from './content-reducer';



export const rootReducer = combineReducers({
    intro: introReducer,
    content: contentReducer
})

export type RootState = ReturnType<typeof rootReducer>