import * as React from 'react';
import MainLayout from '../../../components/Layout/Main';




export default function Home(){

    return(
        <div>
            <MainLayout page="/admin">             
            
            </MainLayout>
        </div>
    );
};
