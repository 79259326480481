import * as React from 'react';
import { Link } from 'react-router-dom';
import '../../scss/style.scss';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './style.scss';

// export enum Pages{
//     home,
//     about,
//     services,
//     contact,
//     landingAdmin
// }

interface IProps{
    active: string;
}

export default function NavigationBar(props: IProps){    
  
  const logo = require('../../images/central_logo.jpg')
    return (
      <Navbar expand="lg" className="bg-white" fixed="top">
        <div className="container">
          <Navbar.Brand className="cb-brand ">                
              <Link to="/" className="navbar-brand">
                <img src={logo} className="logo-img" alt="Central Bodyshop Paintgun Logo" />
              </Link>                          
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarResponsive" />
          <Navbar.Collapse id="navbarResponsive">
            <Nav className="ml-auto">
              <Nav.Item className={`nav-item ${props.active==="/home" && "active"}`} >
                <Link className="nav-link" to="/" >Home</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active==="/about" && "active"}`} >
              <Link className="nav-link" to="/about">About</Link>
              </Nav.Item>              
              <Nav.Item className={`nav-item ${props.active==="/services" && "active"}`} >
              <Link className="nav-link" to="/services">Services</Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${props.active==="/contact" && "active"}`} >
                <Link className="nav-link" to="/contact" >Contact</Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
      //   <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      //   <div className="container">
      //     <h1><Link className="navbar-brand" to="/">centralbodyshop.co.uk</Link></h1>
      //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
      //       <span className="navbar-toggler-icon"></span>
      //     </button>
      //     <div className="collapse navbar-collapse" id="navbarResponsive">
      //       <ul className="navbar-nav ml-auto">
      //         <li className={`nav-item ${props.active===Pages.Home && "active"}`}>
      //           <Link className="nav-link" to="/">Home</Link>
      //         </li>
      //         <li className={`nav-item ${props.active===Pages.About && "active"}`}>
      //           <Link className="nav-link" to="/about">About</Link>
      //         </li>
      //         <li className={`nav-item ${props.active===Pages.Services && "active"}`}>
      //           <a className="nav-link" href="#">Services</a>
      //         </li>
      //         <li className={`nav-item ${props.active===Pages.Contact && "active"}`}>
      //           <a className="nav-link" href="#">Contact</a>
      //         </li>
      //       </ul>
      //     </div>
      //   </div>
      // </nav>
    );
};
