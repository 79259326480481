export default{
    Common: {
        address: "807 Bath Rd, Brislington, Bristol, BS4 5NL. 0117 977 1449"
    },
    Intro:{
        heading: "Professional Accident Repair Centre"
    },
    Contact:{
        heading: "Contact Us..."
    }, 

}