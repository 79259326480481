import { FETCH_CONTENT, FETCH_CONTENT_PENDING } from '../../store/action';
import {IContent} from '../../interfaces';
import axios from 'axios';
import {API_URL} from '../../common/'

export function getContentsForPage(pageId: string) {   
    return (dispatch: (action: any) => void, getState: any) => {
        dispatch({type: FETCH_CONTENT_PENDING}); 
//        axios.get(`https://localhost:8072/contents/${pageId}`)
        axios.get(`${API_URL}/contents/${pageId}`)
        .then (res =>
            dispatch({
                type: FETCH_CONTENT,
                data: res.data as IContent[]
            })       
        )             
    
    };
};